import request from '@/utils/request';

const PATH = '/reservation-category';

export const getReservationCategoryList = (params: any) => request({
  url: PATH,
  method: 'get',
  params,
});

export const getReservationCategory = (uid: string) => request({
  url: `${PATH}/${uid}`,
  method: 'get',
});
