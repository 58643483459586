import request from '@/utils/request';

const PATH = 'business/store/info';

export const getStoreInfoList = (params: any) => request({
  url: PATH,
  method: 'get',
  params: {
    ...params,
    page: params.page - 1,
  },
});

export const getStoreInfo = (idx: any, params: any) => request({
  url: `${PATH}/${idx}`,
  method: 'get',
  params,
});

export const getMainStoreInfoList = (params: any) => request({
  url: `${PATH}/main/list`,
  method: 'get',
  params,
});

export const getSearchStoreInfoList = (params: any) => request({
  url: `${PATH}/search/list`,
  method: 'get',
  params,
});

export const getAiStoreInfoList = (params: any) => request({
  url: `${PATH}/main/aiStore/list`,
  method: 'get',
  params,
});

export const getMapList = (params: any) => request({
  url: `${PATH}/map/list`,
  method: 'get',
  params,
});

export const getNormalProductList = (params: any) => request({
  url: `${PATH}/normal/product`,
  method: 'get',
  params,
});

export const getNormalProductDetail = (uid: any, params: any) => request({
  url: `${PATH}/normal/product/${uid}`,
  method: 'get',
  params,
});
