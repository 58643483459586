
import { getOptionItemList } from '@/api/optionItem';
import { getReservationCategoryList } from '@/api/reservationCategory';
import { getStoreInfoList } from '@/api/storeInfo';
import { addFavoriteItem, deleteFavoriteItem } from '@/api/favoriteItem';
import {
  getLat,
  getLng,
  setLat,
  setLng,
} from '@/utils/cookies';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/user';
import InfiniteLoading from 'vue-infinite-loading';
import Calendar from '@/components/Calendar/index.vue';
import { LatLng } from '../latlng';

@Component({
  components: {
    InfiniteLoading,
    Calendar,
  },
})

export default class extends Vue {
  @Watch('filterOptionVisible')
  private handleChangeFilterOptionVisible() {
    if (!this.filterOptionVisible) {
      (this.$refs.priceSlider as any).$refs.button1.handleMouseLeave();
      (this.$refs.priceSlider as any).$refs.button2.handleMouseLeave();
    }
  }

  @Watch('searchPrice', { deep: true })
  private handleChangeSearchPrice() {
    if (this.searchPrice && this.searchPrice.length === 2) {
      this.listQuery.startPrice = this.searchPrice[0];
      this.listQuery.endPrice = this.searchPrice[1];
    }
  }

  mounted() {
    this.getCategoryList();
    this.getRegionList();
    this.setUserLocation();
    this.searchPrice.toLocaleString();
  }

  private filterDefault = {
    date: '',
    personnel: '성인2명 / 아동1명',
    personnelAdult: 2,
    personnelChild: 1,
    village: '',
  }

  private filterOptionVisible = false;

  private filterOptionChange() {
    this.filterOptionVisible = !this.filterOptionVisible;
  }

  private listQuery: any = {
    searchValue: this.$route.query.searchValue,
    page: 1,
    size: 20,
    startDate: this.$route.query.startDate ? this.$route.query.startDate : moment().format('YYYY-MM-DD'),
    // lat: this.$route.query.lat ? this.$route.query.lat : '37.566826', 유니드
    // lng: this.$route.query.lng ? this.$route.query.lng : '126.9786567', 유니드
    lat: this.$route.query.lat ? this.$route.query.lat : '34.81762395218535', // 남해마늘연구소
    lng: this.$route.query.lng ? this.$route.query.lng : '127.92101314625813', // 남해마늘연구소
    sort: 'distance',
    region: this.$route.query.region ? this.$route.query.region : '',
    startPrice: 0,
    endPrice: 500000,
    categories: '',
    options: '',
    adult: this.$route.query.adult ? this.$route.query.adult : 2,
    child: this.$route.query.child ? this.$route.query.child : 0,
    industryIdx: 3,
    filterCnt: 0,
  };

  private apiUrl = process.env.VUE_APP_COMMON_API;

  private filterSortDrawer = false;

  private storeList: any = [];

  private totalElements: Number = 0;

  private categoryList: any = [];

  private loading = false;

  private searchCategories = [];

  private searchOptions = [];

  private searchDate = moment().format('YYYY-MM-DD');

  private searchPrice = [0, 500000];

  private datePickerVisible = false;

  private regionList = [];

  private isNoMore = false;

  private calendarData = []; // 달력 일별 데이터

  private selectedDay: string | null = '';

  private selectedMonth = moment().format('YYYY-MM');

  private calendarLoading = true;

  private searchAsync = false;

  private datePickerChange() {
    this.datePickerVisible = !this.datePickerVisible;
  }

  private handleChangeMonth(yearMonth: any) {
    this.selectedMonth = moment(yearMonth).format('YYYY-MM');
    this.calendarData = [];
  }

  private handleChoiceDate(startDate: string) {
    this.selectedDay = startDate;
    this.formQuery.startDate = startDate;
  }

  private formQuery: any = {
    startDate: moment().format('YYYY-MM-DD'),
    adult: 1,
    child: 0,
  }

  private sortList = [
    { label: '추천순', value: 'recommand' },
    { label: '거리순', value: 'distance' },
    { label: '평점높은순', value: 'highScore' },
    { label: '리뷰많은순', value: 'highReview' },
  ]

  private leisureSearch = '';

  private handleClickSort(sort: string) {
    this.loading = true;
    this.storeList = [];
    this.filterSortDrawer = false;
    this.listQuery.page = 1;
    this.listQuery.sort = sort;
    this.getStoreList();
  }

  private getSortLabel() {
    let label = '';
    const idx = this.sortList.findIndex((sort: any) => sort.value === this.listQuery.sort);
    if (idx > -1) label = this.sortList[idx].label;
    return label;
  }

  private getStoreList() {
    this.loading = true;
    getStoreInfoList({ ...this.listQuery, useDate: this.listQuery.startDate }).then((res) => {
      this.storeList = this.storeList.concat(res.data.content);
      this.isNoMore = res.data.last;
      this.loading = false;
      this.searchAsync = false;
    });
  }

  // 남해군의 좌표
  // 북서
  private northWest: LatLng = { lat: 34.9203, lng: 127.8795 };

  // 북동
  private northEast: LatLng = { lat: 34.9333, lng: 128.0967 };

  // 남서
  private southWest: LatLng = { lat: 34.6795, lng: 127.8352 };

  // 남동
  private southEast: LatLng = { lat: 34.7412, lng: 128.1107 };

  private isWithinBounds(point: LatLng): boolean {
    const latMin = Math.min(this.southWest.lat, this.southEast.lat);
    const latMax = Math.max(this.northWest.lat, this.northEast.lat);
    const lngMin = Math.min(this.northWest.lng, this.southWest.lng);
    const lngMax = Math.max(this.northEast.lng, this.southEast.lng);

    return point.lat >= latMin && point.lat <= latMax && point.lng >= lngMin && point.lng <= lngMax;
  }

  addSortOption(label: string, value: string) {
    this.sortList.push({ label, value });
  }

  removeSortOptionByValue(value: string) {
    this.sortList = this.sortList.filter((item) => item.value !== value);
  }

  hasSortOption(value: string): boolean {
    return this.sortList.some((item) => item.value === value);
  }

  // 남해군인지 외부인지 비교를 위한 변수
  private latLng = { lat: 0, lng: 0 };

  private async setUserLocation() {
    // 사용자의 위치(위도, 경도)를 쿠키에서 받아옴
    // const lat = getLat();
    // const lng = getLng();
    const lat = 34.83772828886269;
    const lng = 127.89269277576135;

    // 남해군인지 외부인지 비교를 위한 변수
    this.latLng.lat = lat;
    this.latLng.lng = lng;

    if (lat && lng) { // 사용자 위치가 쿠키에 있으면 해당 위치로 리스트 출력
      if (this.isWithinBounds(this.latLng)) { // 사용자 위치가 남해 내부에 있으면
        this.listQuery.lat = lat;
        this.listQuery.lng = lng;

        this.removeSortOptionByValue('recommand');

        this.getStoreList();
      } else { // 사용자 위치가 쿠키에 있고 남해 외부에 있으면
        this.listQuery = {
          ...this.listQuery,
          sort: 'recommand',
          lat,
          lng,
        };
        this.getStoreList();
      }
    } else { // 사용자 위치가 쿠키에 없으면 사용자 위치를 요청
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // 남해군인지 외부인지 비교를 위한 변수
          this.latLng.lat = position.coords.latitude;
          this.latLng.lng = position.coords.longitude;

          if (this.isWithinBounds(this.latLng)) { // 사용자 위치가 쿠키에 없고, 사용자 정보가 남해군 내부에 있으면
            this.removeSortOptionByValue('recommand');

            this.listQuery = {
              ...this.listQuery,
              lat: this.latLng.lat,
              lng: this.latLng.lng,
            };

            setLat(this.latLng.lat);
            setLng(this.latLng.lng);
          } else { // 사용자 위치가 쿠키에 없고, 사용자 정보가 남해군 외부에 있으면
            this.listQuery = {
              ...this.listQuery,
              sort: 'recommand',
              lat,
              lng,
            };
          }
          this.getStoreList();
        },
        (error) => {
          // 만약 사용자가 위치를 거부하면 랜덤함수로 랜덤한 위치로 리스트 출력
          this.listQuery = {
            ...this.listQuery,
            sort: 'recommand',
            lat,
            lng,
          };
          this.getStoreList();
        },
      );
    }
  }

  private getCategoryList() {
    getReservationCategoryList({ typeUid: '15' }).then((res) => {
      this.categoryList = res.data;
    });
  }

  private handleSearch() {
    this.loading = true;

    if (this.searchAsync) {
      this.$message.error('검색중 입니다. 잠시만 기다려 주십시오.');
    } else {
      this.searchAsync = true;
      this.storeList = [];
      this.listQuery.page = 1;
      this.listQuery.categories = this.searchCategories.join(',');
      this.listQuery.options = this.searchOptions.join(',');
      this.getStoreList();
    }
  }

  private getRegionList() {
    getOptionItemList('REGION').then((res) => {
      this.regionList = res.data;
    });
  }

  private handleResetFormQuery() {
    this.formQuery = {
      adult: this.listQuery.adult,
      child: this.listQuery.child,
      startDate: this.listQuery.startDate,
    };
    const calendarRef: any = this.$refs.calendar;
    if (calendarRef) calendarRef.removeActiveClass();
  }

  private handleApplyFormQuery() {
    this.listQuery = {
      ...this.listQuery,
      ...this.formQuery,
    };
    this.datePickerVisible = false;
    this.handleSearch();
  }

  private handleAddFavoriteItem(idx: any) {
    if (UserModule.token) {
      addFavoriteItem({ storeInfoIdx: idx }).then(() => {
        this.$message.success('저장되었습니다.');
      });
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
  }

  private handleApplyFilter() {
    this.filterOptionVisible = false;
    this.handleSearch();
    this.listQuery.filterCnt = this.getFilterList().length; /* 검색영역의 선택한 필터 갯수 */
  }

  private getFilterList() {
    const filterList: any = [];

    this.searchCategories.forEach((category: any) => {
      const index = this.categoryList.findIndex((x: any) => x.uid === category);
      if (index > -1) filterList.push(this.categoryList[index].name);
    });

    return filterList;
  }

  private handleMoreStoreList($state: any) {
    this.listQuery.page += 1;
    this.loading = true;
    getStoreInfoList({ ...this.listQuery, useDate: this.listQuery.startDate }).then((res) => {
      this.loading = false;
      this.storeList = this.storeList.concat(res.data.content);
      if (res.data.content.length > 0) {
        $state.loaded();
      } else {
        this.isNoMore = true;
        $state.complete();
      }
    }).catch((error) => {
      this.$message.error('정보를 불러오는데 실패했습니다.');
      this.loading = false;
      $state.complete();
    });
  }

  private truncateText(text: string, maxLength: number): string {
    if (text.length > maxLength) {
      return `${text.slice(0, maxLength)}...`;
    }
    return text;
  }

  private distanceType(distance: number | string): number {
    const value = Number(distance);
    if (value > 1000) {
      return value / 1000;
    }
    return 0;
  }

  private handleDatePickerChange() {
    if (!this.datePickerVisible) {
      document.documentElement.classList.remove('noscr');
    } else {
      document.documentElement.classList.add('noscr');
    }
  }

  private favoriteFlag = false;

  private async handleAddFavoriteStay(event: Event, idx: any, type: number) {
    if (this.favoriteFlag) {
      this.$message.info('저장중입니다.');
      return;
    }
    this.favoriteFlag = true;
    if (UserModule.token) {
      const target = (event.target as HTMLElement)?.parentElement?.parentElement?.parentElement?.parentElement;
      if (target?.classList.contains('active')) {
        if (target.getAttribute('data-id')) {
          await deleteFavoriteItem(target.getAttribute('data-id')).then(() => {
            this.$message.success('삭제되었습니다.');
            target.classList.remove('active');
            target.removeAttribute('data-id');
          });
        } else {
          this.$message.info('해당 서비스를 찾을 수 없습니다.');
        }
      } else if (target && !(target?.classList.contains('active'))) {
        let query = {};
        if (type === 1) query = { stayIdx: idx };
        else if (type === 2) query = { storeInfoIdx: idx };
        await addFavoriteItem(query).then((res) => {
          this.$message.success('저장되었습니다.');
          target.classList.add('active');
          target.setAttribute('data-id', res.data);
        });
      }
    } else {
      this.$message.info('로그인이 필요한 서비스입니다.');
    }
    this.favoriteFlag = false;
  }

  private leisureNavigateToDetail(storeIdx: string) {
    this.$router.push({ name: 'LeisureIndex', params: { storeIdx } });
  }

  private handleViewStore(idx: any) {
    this.$router.push({
      name: 'LeisureView',
      params: { idx },
      query: {
        startDate: this.listQuery.startDate,
        endDate: this.listQuery.endDate,
        adult: this.listQuery.adult,
        child: this.listQuery.child,
      },
    });
  }

  private calendarDate = '';

  private handlePickCalendar() {
    this.calendarDate = moment(this.calendarDate, 'YYYY.MM.DD').format('YYYY-MM-DD');
  }

  private handleDesktopApplyFilter() {
    this.listQuery.startDate = this.calendarDate;
    this.handleApplyFilter();
  }
}
