import request from '@/utils/request';

const PATH = '/favorite/item';

export const getFavoriteItemList = (industryIdx: string, listQuery: any) => request({
  url: `${PATH}/${industryIdx}`,
  method: 'get',
  params: {
    ...listQuery,
    page: listQuery.page - 1,
  },
});

export const addFavoriteItem = (data: any) => request({
  url: PATH,
  method: 'post',
  data,
});

export const deleteFavoriteItem = (idx: any) => request({
  url: `${PATH}/${idx}`,
  method: 'delete',
});
