import request from '@/utils/request';

const PATH = '/option/item';

export const getOptionItemList = (searchType: string) => request({
  url: PATH,
  method: 'get',
  params: {
    type: searchType,
  },
});
